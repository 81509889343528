/* SidePanel.css 
IMPORTANT: this is used to hide scrollbar on the SidePanel
*/
.sidePanel {
  height: calc(100vh - 66px);
  width: 25vw;
  min-width: 300px;
  max-width: 550px;
  background-color: transparent;
  border: none;
  position: absolute;
  top: 64px;
  left: 0;
  z-index: 1;
  overflow-y: auto;
  scrollbar-width: none; /* For Firefox */
}

.sidePanel::-webkit-scrollbar {
  display: none; /* For WebKit browsers */
}
